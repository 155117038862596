import React from 'react';

const Chat = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="24"
      fill="none"
      viewBox="0 0 26 24"
    >
      <path
        fill="#1C102F"
        d="M3.649.02H22.35c.868 0 1.655.353 2.225.924.57.57.924 1.357.924 2.224v12.67c0 .868-.354 1.655-.924 2.225a3.14 3.14 0 01-2.225.924h-10.28l-6.785 4.834a.862.862 0 01-1.364-.703h-.003v-4.13h-.27a3.138 3.138 0 01-2.225-.925A3.14 3.14 0 01.5 15.838V3.168c0-.867.354-1.654.924-2.224A3.138 3.138 0 013.649.02zm18.7 1.732H3.65c-.389 0-.743.16-1.001.417a1.41 1.41 0 00-.417 1v12.67c0 .39.16.745.417 1.001.257.257.612.417 1 .417h1.137c.478 0 .866.388.866.867v3.32l5.594-3.986a.86.86 0 01.555-.2H22.35c.389 0 .743-.16 1.001-.418a1.41 1.41 0 00.417-1V3.17c0-.39-.16-.743-.417-1.001a1.412 1.412 0 00-1.002-.417z"
      ></path>
      <path
        fill="#1C102F"
        fillRule="evenodd"
        d="M7.155 10.802c.835 0 1.515-.68 1.515-1.514s-.681-1.515-1.515-1.515c-.834 0-1.514.681-1.514 1.515 0 .834.679 1.514 1.514 1.514zM12.999 10.802c.835 0 1.514-.68 1.514-1.514S13.833 7.773 13 7.773c-.835 0-1.515.681-1.515 1.515 0 .834.68 1.514 1.515 1.514zM18.845 10.802c.835 0 1.514-.68 1.514-1.514s-.68-1.515-1.514-1.515-1.515.681-1.515 1.515c0 .834.68 1.514 1.515 1.514z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Chat;
